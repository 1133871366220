<mat-form-field
  class="w-100"
  [appearance]="appearance"
  *ngIf="filteredOptions$ | async as filteredOptions"
>
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [matAutocomplete]="auto"
    [formControl]="control"
  />
  <div matSuffix class="suffix">
    <button
      class="reset-button"
      [hidden]="!control.value"
      (click)="control.setValue(''); $event.preventDefault()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option
      class="autocomplete__item"
      *ngFor="let option of filteredOptions; trackBy: identify"
      [value]="option.value"
    >
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
