import { Component, Input, OnChanges, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WrappedFormControlComponent } from 'src/app/components/shared/wrapped-form-control/wrapped-form-control.component';
import { normalizeSearchTerm } from 'src/app/shared/utils/adapters';
import { MatFormFieldAppearance } from '@angular/material/form-field';

export interface AutoCompleteOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-auto-complete',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, ReactiveFormsModule],
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent
  extends WrappedFormControlComponent
  implements OnChanges
{
  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  @Input() options: AutoCompleteOption[] = [];
  @Input() label: string;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  filteredOptions$: Observable<AutoCompleteOption[]>;

  ngOnChanges() {
    if (this.options) {
      this.filteredOptions$ = this.control.valueChanges.pipe(
        startWith(this.control.getRawValue()),
        map((value) => this._filter(value))
      );
    }
  }

  private _filter(value: string): AutoCompleteOption[] {
    const normalizedValue = normalizeSearchTerm(value);

    return this.options
      .filter((option) =>
        normalizeSearchTerm(option.label).includes(normalizedValue)
      )
      .sort(
        (a, b) =>
          normalizeSearchTerm(a.label).indexOf(normalizedValue) -
          normalizeSearchTerm(b.label).indexOf(normalizedValue)
      );
  }

  identify(_index: number, item: AutoCompleteOption) {
    return item.value;
  }

  displayFn = (value: string): string => {
    if (!value || !this.options) return '';
    const label = this.options.find((option) => option.value === value)?.label;
    return label || value;
  };
}
